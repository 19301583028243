import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@ui/Button';
import Input from '@ui/Input';
import { logEvent } from '@util/analytics';
import {
  createCustomer,
  saveCard,
} from '@util/firestore/payments/payments.service';
import { getUserById, updateUserByKey } from '@util/firestore/users';
import { STRIPE_ELEMENT_OPTIONS } from '@util/get-stripe';
import { useAuth } from 'context/AuthContext';
import { useStripeContext } from 'context/StripeContext';
import { useToastContext } from 'context/ToastContext';
import { UserDocument } from 'models/user';
import React from 'react';
import BaseModal from './BaseModal';

interface PaymentFormProps {
  userDoc: UserDocument;
  dismiss: (paymentMethodId?: string) => void;
}
const PaymentForm = ({ userDoc, dismiss }: PaymentFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = React.useState(false);
  const [cardError, setCardError] = React.useState<string | null>(null);
  const [cardName, setCardName] = React.useState('');
  const { showErrorToast } = useToastContext();

  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    if (!stripe || !elements) return;
    elements.submit();

    setIsLoading(true);

    const res = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          name: cardName,
          email: userDoc.email,
          phone: userDoc.phone,
        },
      },
    });
    if (res.error) {
      setCardError(
        res.error.message ??
          'Something went wrong. Please try again or contact support.'
      );
    } else {
      try {
        if (!userDoc?.uid) throw new Error('No user id');
        const currUserDoc = await getUserById(userDoc?.uid);
        let customer_id = currUserDoc?.customer_id;
        if (!currUserDoc?.customer_id) {
          customer_id = await createCustomer(res.paymentMethod.id);
          if (userDoc?.uid && typeof customer_id === 'string') {
            await updateUserByKey(userDoc.uid, 'customer_id', customer_id);
          }
        }
        if (!customer_id) {
          showErrorToast(
            'Error saving payment method. Please try again or contact support.'
          );
          return;
        }
        await saveCard(res.paymentMethod.id, customer_id);
        queryClient.invalidateQueries({
          queryKey: ['paymentMethods'],
        });
        queryClient.invalidateQueries({
          queryKey: ['authUser'],
        });
        const id = res.paymentMethod.id;
        logEvent('add_payment_info', {
          id,
          is_payout: false,
          brand: res.paymentMethod.card?.brand ?? 'unknown',
        });
        dismiss(id);
      } catch (e) {
        const err = e as Error;
        const msg =
          err.message?.split('rror: ')?.pop() ??
          'Something went wrong. Please try again.';
        setCardError(msg);
      }
    }
    setIsLoading(false);
  };
  return (
    <>
      <div className="mb-5 mt-5">
        <label className="text[14.88px] pb-2 text-[#30313e]">Full Name</label>
        <Input
          full
          stripe
          type="text"
          focus="default"
          autoComplete="name"
          placeholder="John Doe"
          onChange={(ev) => setCardName(ev.target.value)}
        />
      </div>
      <PaymentElement
        options={{ fields: { billingDetails: { name: 'never' } } }}
      />
      <div className="pb-[2.5rem] pt-5 text-brand-lightest-black">
        Payment is powered by Stripe and we do not store sensitive payment
        information on our servers.
      </div>
      {cardError && (
        <div className="mb-3 pb-5 text-[1.3rem] font-semibold text-brand-red">
          Error: {cardError}
        </div>
      )}
      <div className="flex grow flex-col justify-end font-semibold text-brand-secondary sm:flex-row">
        <Button
          type="text"
          text="Cancel"
          onClick={() => dismiss()}
          className="!rounded-[2rem] !py-12  text-[1.8rem] sm:rounded-lg sm:py-4"
        />
        <Button
          className="!rounded-[2rem] !py-12  text-[1.8rem] sm:rounded-lg sm:py-4"
          type="secondary"
          text="Save Payment"
          loading={isLoading}
          onClick={() => handleSubmit()}
        />
      </div>
    </>
  );
};
interface AddPaymentMethodModalProps {
  isOpen: boolean;
  dismiss: (paymentMethodId?: string) => void;
  isFullScreen?: boolean;
}
const AddPaymentMethodModal = ({
  isOpen,
  dismiss,
  isFullScreen,
}: AddPaymentMethodModalProps) => {
  const { stripePromise } = useStripeContext();
  const { userDoc } = useAuth();
  if (!userDoc) return null;

  return (
    <BaseModal
      isFullScreen={isFullScreen}
      isOpen={isOpen}
      dismiss={dismiss}
      title={
        <h1
          className={`text-[2.4rem] font-semibold ${isFullScreen ? 'p-4' : ''}`}
        >
          Add Payment Method
        </h1>
      }
    >
      <div
        className={`flex max-h-[75vh] flex-col gap-[1.6rem] sm:w-[50rem] sm:p-[2.4rem] ${
          isFullScreen ? 'p-4' : ''
        }`}
      >
        <div className="h-full overflow-y-scroll px-4">
          <Elements
            stripe={stripePromise}
            options={{
              ...STRIPE_ELEMENT_OPTIONS,
              paymentMethodCreation: 'manual',
              mode: 'setup',
              currency: 'usd',
            }}
          >
            <PaymentForm userDoc={userDoc} dismiss={dismiss} />
          </Elements>
        </div>
      </div>
    </BaseModal>
  );
};

export default AddPaymentMethodModal;

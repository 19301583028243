import { MouseEventHandler } from 'react';

export const RadioButton = ({
  label,
  checked,
  onClick,
  children,
  disabled,
}: {
  label?: string;
  checked: boolean;
  onClick: MouseEventHandler;
  children?: React.ReactNode;
  disabled?: boolean;
}) => {
  return (
    <button
      onClick={onClick}
      className={`flex h-fit w-fit cursor-pointer select-none items-center gap-[.8rem] rounded-full ${
        disabled ? 'opacity-50' : 'opacity-100'
      }`}
      type="button"
      disabled={disabled}
    >
      <div
        className={`h-[1.6rem] w-[1.6rem] overflow-clip rounded-full outline outline-1 ${
          checked ? 'outline-brand-secondary' : 'outline-[#D9D9D9]'
        }`}
      >
        {checked && (
          <div className="h-[1.6rem] w-[1.6rem] rounded-full border-[.25rem] border-brand-white bg-brand-secondary" />
        )}
      </div>
      {label} {children}
    </button>
  );
};

export default RadioButton;

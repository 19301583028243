import { FedExIcon, USPSIcon } from '@c/icons';
import { UPS } from '@c/icons/carriers';
import RadioButton from '@ui/RadioButton';
import SafeImage from '@ui/SafeImage';
import { ProductDocument } from '@models/product';
import { Rate } from '@util/firestore/shipengine';
import { useRates } from '@util/hooks/useRates';
import { formatCurrency } from '@util/index';
import { AddressDocument } from '@models/address';
import { Button } from '..';
import { useEffect, useState } from 'react';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { HiPencil } from 'react-icons/hi2';
import { CanadaPost } from '@c/icons/CanadaPost';

function ItemShippingSelect({
  product,
  shipment,
  shipTo,
  hideProduct = false,
  chooseCheapestByDefault = false,
  hide = false,
  onOptionSelected,
  selectedRateId,
  offer_amount,
  customsInfo,
  onError,
}: {
  product?: ProductDocument;
  shipment?: ProductDocument['shipment'];
  shipTo: AddressDocument;
  hideProduct?: boolean;
  chooseCheapestByDefault?: boolean;
  hide?: boolean;
  onOptionSelected?: ({
    rate,
    product_id,
    seller_id,
  }: {
    rate: Rate;
    product_id: string;
    seller_id: string;
  }) => void;
  selectedRateId: string | null;
  offer_amount?: number;
  customsInfo?: {
    product_title: string;
    product_category: string;
    product_sku: string;
    product_price: number;
  };
  onError?: (error: Error) => void;
}) {
  const {
    allRates,
    rates,
    ratesError,
    ratesLoading,
    selectOption,
    foundRate,
    showAllRates,
    setShowAllRates,
    getFlatRateShipping,
    isPopularRate,
    RATE_DISPLAY_LIMIT,
  } = useRates({
    product,
    shipment,
    shipTo,
    chooseCheapestByDefault,
    offer_amount,
    customsInfo,
    onOptionSelected,
    selectedRateId,
  });

  const [showShippingList, setShowShippingList] = useState(false);

  useEffect(() => {
    if (ratesError) {
      onError?.(ratesError as Error);
    }
  }, [ratesError, onError]);

  const selectedShippingOption = rates.find(
    (r) => r.rate_id === selectedRateId
  );

  if (hide) return null;

  const handleOptionSelect = (option: Rate) => {
    selectOption(option);

    onOptionSelected?.({
      rate: option,
      product_id: product?.id ?? '',
      seller_id: product?.seller_id ?? '',
    });

    setShowShippingList(false);
  };

  const showSelectShippingButton =
    !showShippingList && !selectedRateId && !ratesLoading;

  const flatRate = getFlatRateShipping();

  return (
    <div
      className={`m-1 ${
        !showShippingList && selectedRateId && 'border-b border-zinc-300'
      } p-6 outline-brand-lightest-gray`}
    >
      {product && !hideProduct && (
        <div className="flex items-center gap-x-4">
          <SafeImage
            alt={product.title}
            src={product.thumbnail}
            height={100}
            width={100}
            className="aspect-square h-32 w-32 rounded-2xl object-cover"
          />

          <div className="flex w-full items-start justify-between">
            <div className="flex flex-col gap-y-2">
              <h5 className="line-clamp-1 w-[25rem] whitespace-pre-wrap text-[1.6rem] font-semibold sm:w-[28rem]">
                {product.title}
              </h5>

              <div className="flex items-center gap-x-2">
                <span className="text-elipsis line-clamp-1 text-[1.6rem] font-medium text-brand-dark-gray sm:pr-10">
                  {product.category1 === 'Boots' ||
                  product.category1 === 'Helmets'
                    ? product.category1
                    : product.category2
                    ? product.category2
                    : product.location_display
                    ? product.location_display
                    : product.category1
                    ? product.category1
                    : product.category}
                  {product.size && (
                    <>
                      {product.size?.letter
                        ? `, Size: ${product.size?.letter}`
                        : ''}
                      {product.size?.number
                        ? `${product.size?.letter ? '/ ' : ''} Size: ${
                            product.size?.number
                          }`
                        : ''}
                      {!!product.variations?.length &&
                      product.variations.length > 1
                        ? ', Size: Multiple Sizes'
                        : ''}
                    </>
                  )}
                </span>
              </div>
            </div>

            <div className="flex flex-col items-end gap-y-2">
              <p className="text-[1.6rem] font-semibold">
                {formatCurrency(product.price)}
              </p>

              <p className="text-[1.6rem] text-zinc-500">
                Qty: {product.quantity ?? 1}
              </p>
            </div>
          </div>
        </div>
      )}
      {/* options */}
      <div className="mt-8">
        {/* flat rate */}
        {product?.is_flat_rate === false || shipment ? (
          // multiple options
          <div className="flex w-full flex-col gap-[1.6rem]">
            {/* loading */}
            {ratesLoading && (
              <div className="flex w-full flex-col gap-4">
                <span className="animate-pulse text-[1.4rem] font-semibold">
                  Loading shipping options...
                </span>
                <div className="w-full animate-pulse rounded-[.4rem] bg-brand-lightest-gray p-4" />
                <div className="w-full animate-pulse rounded-[.4rem] bg-brand-lightest-gray p-4" />
                <div className="w-full animate-pulse rounded-[.4rem] bg-brand-lightest-gray p-4" />
              </div>
            )}
            {/* shipping rate options */}
            {!!rates?.length &&
              showShippingList &&
              rates.map((option) => {
                const selected =
                  foundRate?.service_code === option.service_code;
                const showOption =
                  showAllRates ||
                  isPopularRate(option) ||
                  rates.length === allRates?.length;

                return (
                  showOption && (
                    <div key={option.rate_id} className={`flex items-center`}>
                      <div className="flex w-[8rem] justify-center">
                        {option.carrier_code === 'fedex' ? (
                          <FedExIcon />
                        ) : option.carrier_code === 'ups' ? (
                          <UPS />
                        ) : option.carrier_code === 'canada_post' ? (
                          <CanadaPost />
                        ) : (
                          <USPSIcon />
                        )}
                      </div>
                      <div className="flex items-center gap-5">
                        <RadioButton
                          onClick={() => handleOptionSelect(option)}
                          checked={
                            selected || selectedRateId === option.rate_id
                          }
                          label=""
                        />
                        <div>
                          <h5 className="text-[1.6rem] font-semibold text-brand-secondary">
                            {option.delivery_days === null
                              ? 4
                              : option.delivery_days}{' '}
                            Day
                            {option.delivery_days === null ||
                            option.delivery_days > 1
                              ? 's'
                              : ''}
                          </h5>
                          <p className="text-[1.4rem] text-brand-gray">
                            {option.service_type}
                          </p>
                        </div>
                      </div>
                      <div className="ml-auto text-[1.6rem] font-semibold">
                        {formatCurrency(option.total_amount)}
                      </div>
                    </div>
                  )
                );
              })}
            {!!allRates?.length &&
              allRates.length > RATE_DISPLAY_LIMIT &&
              showShippingList && (
                <Button
                  type="text"
                  text={showAllRates ? 'Show Less' : 'Show More'}
                  onClick={() => setShowAllRates(!showAllRates)}
                />
              )}
            {/* no shipping rates found */}
            {(ratesError as Error) && !ratesLoading && (
              <div className="text-center text-brand-red">
                {(ratesError as Error).message}
              </div>
            )}
            {!showShippingList && !ratesLoading && selectedRateId && (
              <div
                key={selectedShippingOption?.rate_id}
                className={`flex h-[10rem] w-full items-center justify-between rounded-[2rem] bg-zinc-100 px-8 py-4`}
              >
                <div className="flex items-center gap-x-4">
                  <div className="flex w-[8rem] justify-center">
                    {selectedShippingOption?.carrier_code === 'fedex' ? (
                      <FedExIcon />
                    ) : selectedShippingOption?.carrier_code === 'ups' ? (
                      <UPS />
                    ) : selectedShippingOption?.carrier_code ===
                      'canada_post' ? (
                      <CanadaPost />
                    ) : (
                      <USPSIcon />
                    )}
                  </div>

                  <div className="flex items-center gap-5">
                    <div>
                      <h5 className="text-[1.6rem] font-semibold text-brand-secondary">
                        {selectedShippingOption?.delivery_days} Day
                        {selectedShippingOption?.delivery_days &&
                        selectedShippingOption.delivery_days > 1
                          ? 's'
                          : ''}
                      </h5>
                      <p className="text-[1.4rem] text-brand-gray">
                        {selectedShippingOption?.service_type}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-x-8">
                  <div className="ml-auto text-[1.8rem] font-semibold">
                    {selectedShippingOption?.total_amount !== undefined
                      ? formatCurrency(selectedShippingOption.total_amount)
                      : ''}
                  </div>

                  <HiPencil
                    className="h-10 w-10 text-black"
                    onClick={() => {
                      setShowShippingList(!showShippingList);
                    }}
                  />
                </div>
              </div>
            )}
            {showSelectShippingButton && (
              <div
                className="w-full"
                onClick={() => {
                  setShowShippingList(true);
                }}
              >
                <div className="flex w-full items-center justify-between rounded-2xl border-2 border-dashed border-zinc-300 p-6 text-[1.8rem]">
                  <h3 className="font-semibold text-black">Select shipping</h3>

                  <PlusCircleIcon
                    fill="#C41719"
                    className="aspect-square h-12 w-12 rounded-full "
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="mt-8 flex w-full items-center justify-between rounded-2xl bg-brand-off-white p-4">
            {flatRate === null ? (
              <div>
                <h5 className=" text-[1.6rem] font-semibold">
                  Error: No Shipping Available
                </h5>
                <p className="text-[1.4rem] text-brand-gray">
                  Please contact the seller to add shipping to your country
                </p>
              </div>
            ) : (
              <>
                <div>
                  <h5 className="text-[1.6rem] font-semibold text-brand-secondary">
                    Flat Rate Shipping
                  </h5>
                  <p className="text-[1.4rem] text-brand-gray">
                    Shipping carrier selected by seller
                  </p>
                </div>
                <div className="text-[1.6rem] font-semibold">{flatRate}</div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ItemShippingSelect;

export const CanadaPost = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 500 500">
    <defs>
      <style>{`
      .cls-1 {
        display: none;
        fill: #004890;
      }
      .cls-2 {
        fill: #fff;
      }
      .cls-2, .cls-3 {
        fillRule: evenodd;
      }
      .cls-4 {
        fill: #231f20;
        font-family: Roboto-Medium, Roboto;
        font-size: 60px;
        font-weight: 500;
        letter-spacing: 0em;
      }
      .cls-3 {
        fill: #d11c00;
      }
    `}</style>
    </defs>
    <g id="Layer_1">
      <rect className="cls-1" width="500" height="500" />
      <g>
        <path
          className="cls-3"
          d="M249.7,84.3c-74.3,0-134.4,59.8-134.4,133.8s60.1,133.8,134.4,133.8,135-59.8,135-133.8-60.7-133.8-135-133.8Z"
        />
        <path
          className="cls-2"
          d="M208.9,236.2l9.7-9.7,110.5-5.1,4.5-4.6h-107.9l-59.5-68.8,197.1,65.7-65.2,65.7h-131.9l12.9-13.5,110.5-5.1,4.5-4.5h-104.7l9.7-9.7,109.8-5.1,5.2-5.1h-105.4Z"
        />
      </g>
      <text className="cls-4" transform="translate(31.6 454)">
        <tspan x="0" y="0">
          CANADA POST
        </tspan>
      </text>
    </g>
  </svg>
);
